/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import Section, { SectionContent } from "../components/sections/Section";
import { H2, H3 } from "../components/Common";
import ContactSection from "../components/sections/ContactSection";
import MonitorsGrid from "../components/MonitorsGrid";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";
import theme from "src/components/theme";

const HeaderSection = styled(Section)`
  background-color: ${theme.lightBackgroundColor};

  ${SectionContent} {
    display: flex;
    align-items: center;
    flex-direction: column;

    ${H2} {
      text-align: center;
    }

    text-align: center;
  }
`;

const DescriptionSection = styled(Section)`
  background-color: ${p => (p.odd ? theme.lightBackgroundColor : theme.backgroundColor)};

  ${SectionContent} {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.monitorsPageVerticalBreakpoint}) {
      align-items: flex-start;
      flex-direction: ${p => (p.odd ? "row-reverse" : "row")};
    }
  }
`;

const DescriptionScreenshot = styled.div`
  flex: 1 0 100%;
  max-width: 600px;
  width: 100%;
`;

DescriptionSection.propTypes = {
  odd: PropTypes.bool,
};

const DescriptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  flex: 0 1 auto;
  align-items: center;

  @media (min-width: ${theme.monitorsPageVerticalBreakpoint}) {
    align-items: flex-start;
  }
`;

const DescriptionTitle = styled(H3)`
  margin: 20px 0 10px 0;
  text-align: center;
`;

const DescriptionText = styled.span`
  text-align: center;

  @media (min-width: ${theme.monitorsPageVerticalBreakpoint}) {
    text-align: left;
  }
`;

const MonitorPageTemplate = ({ icon, title, description, monitor, sections }) => {
  return (
    <>
      <HeaderSection>
        {icon}
        <H2>{title}</H2>
        <span>{description}</span>
      </HeaderSection>
      {sections.map((section, index) => (
        <DescriptionSection key={`section${index}`} odd={Boolean(index % 2)}>
          <DescriptionScreenshot>{section.screenshot}</DescriptionScreenshot>
          <DescriptionTextContainer>
            <DescriptionTitle>{section.title}</DescriptionTitle>
            <DescriptionText>{section.description}</DescriptionText>
          </DescriptionTextContainer>
        </DescriptionSection>
      ))}
      <MonitorsGrid currentMonitor={monitor} />
      <ContactSection />
    </>
  );
};

MonitorPageTemplate.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  monitor: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      screenshot: PropTypes.element.isRequired,
    }),
  ),
};

const IconContainer = styled.div`
  border-radius: 8px;
  border: 2px solid ${theme.monitorIconBorder};
  flex: none;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MonitorScreenshot = styled(Img)`
  width: 100%;
`;

export default function Template({ data }) {
  const { markdownRemark } = data;

  const sections = markdownRemark.frontmatter.sections.map(section => ({
    title: section.title,
    description: section.description,
    screenshot: <MonitorScreenshot fluid={section.screenshot.childImageSharp.fluid} />,
  }));

  return (
    <Layout title={markdownRemark.frontmatter.title} header={<Header variant="dark" />} footer={<Footer />}>
      <MonitorPageTemplate
        icon={
          <IconContainer>
            <Img fixed={markdownRemark.frontmatter.icon.childImageSharp.fixed} />
          </IconContainer>
        }
        title={markdownRemark.frontmatter.title}
        description={markdownRemark.frontmatter.description}
        monitor={markdownRemark.frontmatter.monitor}
        sections={sections}
      />
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export const pageQuery = graphql`
  query MonitorByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        icon {
          childImageSharp {
            fixed(width: 24, height: 24) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        title
        description
        monitor
        sections {
          title
          description
          screenshot {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
